import {Controller} from "stimulus";
import Clusterize from "clusterize.js";
import {debounce} from "lodash";
import {buildPagination} from "../../helpers/advanced_paging";
import {
  showErrorDialog,
} from "../../shared/common/dialogs/error_dialog_controller";
import {showEmailDetailsDialog} from "./email_details_dialog_controller";
import {showEmailResendStatusDialog} from "./email_resend_dialog_controller";

const SPINNER_MARKUP = `
  <tr>
    <td colspan="7">loading...</td>
  </tr>
  <tr>
    <td colspan="7"><div class="loading-spinner loading-spinner-lg"></div></td>
  </tr>
`;

const MAX_RECORD_COUNT = 10000;

/** Emails List Controller */
export default class extends Controller {
  static targets = [
    "emailsList",
    "emailsListBody",
    "emailStatusQuery",
    "emailTemplateQuery",
    "startDateQuery",
    "endDateQuery",
    "searchTermQuery",
    "recordsPerPageQuery",
    "pagination",
  ]

  /**
    * Register the controller
    */
  connect() {
    this.pageNumber = 1;
    this.emailsResourceUrl = this.data.get("emailsResourceUrl");
    this.debounceFetchEmails = debounce(this.fetchEmails, 1000);
    this.totalRecords = 0;
    this.fetchEmails();
  }

  /**
   * Show the dialog
   *
   * @param {Event} e event that triggered showDialog action
   */
  showDialog(e) {
    const emailId = e.currentTarget.getAttribute("data-email-id");
    showEmailDetailsDialog(emailId);
  }

  /**
   * Handles hard errors in fetch
   *
   * @param {Error} err
   */
  handleError(err) {
    console.error("Error: ", err);
    this.emailsListBodyTarget.innerHTML = "";
    if (String(err).match(/Unauthorized/i)) {
      // no need to do anything here.
    } else {
      showErrorDialog(this.data.get("errorMessage"));
    }
  }

  /**
   * Handles success and failure response in email details fetch
   *
   * @param {Object} responseJson parsed response json
   */
  handleResponse(responseJson) {
    if (responseJson.success) {
      this.renderPagination(responseJson.pagination);
      this.renderList(responseJson.rows);
    } else {
      this.handleError(new Error(responseJson.status));
    }
  }

  /**
   * Fetch email details
   */
  fetchEmails() {
    const request = { method: "GET" };
    this.renderLoading();

    fetch(this.formatEmailsQueryUrl("json"), window.acima.fetchInit(request))
      .then(
        res => {
          if (!res.ok) {
            throw Error(res.statusText);
          }
          return res;
        }
      )
      .then(res => res.json())
      .then(json => this.handleResponse(json))
      .catch(err => this.handleError(err));
  }

  /**
   * Renders loading markup
   */
  renderLoading() {
    this.emailsListBodyTarget.innerHTML = SPINNER_MARKUP;
  }

  /**
   * Renders email list as table
   *
   * @param {array} emails array of json data for emails
   */
  renderList(emails) {
    new Clusterize({
      rows: emails.map((rowData) => this.renderRow(rowData)),
      scrollId: this.emailsListTarget.id,
      contentId: this.emailsListBodyTarget.id,
    });
  }

  /**
   * Renders individual row of emails list data
   *
   * @param {Object} rowData
   *
   * @return {string} row markup for email list
   */
  renderRow(rowData) {
    return `
      <tr>
        <td>${rowData.lease_url}</td>
        <td>${rowData.customer_name}</td>
        <td>${rowData.created_at}</td>
        <td>${rowData.status}</td>
        <td>${rowData.to}</td>
        <td>${rowData.email_template}</td>
        <td><a
          data-action="click->users--emails--list#showDialog"
          data-email-id="${rowData.id}"
        >Email Details</a></td>
        <td class="action-column"><button
          data-action="click->users--emails--list#resendEmail"
          data-email-id="${rowData.id}"
          class="btn btn-default btn-xs contract-lock-free"
        >Resend</button></td>
      </tr>
    `;
  }

  /**
   * Resend the email with current data
   * @param {e} e click event
   * @return { fetch } fetch request to post email resend
   */
  resendEmail(e) {
    const url = "/users/email_resends";
    const emailId = e.currentTarget.getAttribute("data-email-id");
    const params = JSON.stringify({id: emailId});

    const fetchWith = window.acima.fetchInit({method: "POST", body: params});

    return fetch(url, fetchWith)
        .then((response) => response.json())
        .then((response) => this.handleResendResponse(response));
  }

  /**
   * Determine message and kickoff dialog
   *
   * @param {response} response from fetch request
   */
  handleResendResponse(response) {
    if (response.success) {
      var status = this.data.get("resendSuccess");
    } else {
      var status = this.data.get("resendFailure");
    }
    showEmailResendStatusDialog(status, response.message);
  }

  /**
   * TODO move the message into translations
   *
   * Downloads the xlsx version of the results
   */
  downloadXlsx() {
    if (this.totalRecords > MAX_RECORD_COUNT) {
      showErrorDialog(
          `The maximum records that can be exported is ${MAX_RECORD_COUNT}`
      );
      return;
    }

    const url = this.formatEmailsQueryUrl("xlsx");
    document.location.assign(url);
  }

  /**
   * Builds the request URL with query params and format
   *
   * @param {string} format the format of the request, json vs xlsx
   *
   * @return {URL} the formatted URL for requests
   */
  formatEmailsQueryUrl(format) {
    const queryParams = {
      email_status: this.emailStatusQueryTarget.value,
      email_template: this.emailTemplateQueryTarget.value,
      start_date: this.startDateQueryTarget.value,
      end_date: this.endDateQueryTarget.value,
      search_term: this.searchTermQueryTarget.value,
      page: this.pageNumber,
      records_per_page: this.recordsPerPageQueryTarget.value,
    };
    const emailsUrl = new URL(`${this.emailsResourceUrl}.${format}`);

    Object
        .keys(queryParams)
        .forEach((key) => emailsUrl.searchParams.append(key, queryParams[key]));

    return emailsUrl;
  }

  // Pagination Interactions

  /**
   * Updates selected page number and fetches emails
   *
   * @param {Event} e event that triggered changing of page action
   */
  changePage(e) {
    if (e.currentTarget.parentElement.classList.contains("disabled")) {
      return;
    }

    this.pageNumber = e.currentTarget.getAttribute("data-page");
    this.fetchEmails();
  }

  /**
   * Renders pagination controls
   *
   * @param {Object} paginationData
   */
  renderPagination(paginationData) {
    this.paginationTarget.innerHTML = buildPagination({
      ...paginationData,
      action: "data-action='users--emails--list#changePage'",
    });
  }
}
