import { Controller } from "stimulus";
import { start } from "@anycable/turbo-stream";
import cable from "../../channels/helpers/cable";

export default class extends Controller {
  static targets = [
    "approvalsTab",
    "fundingsTab",
    "approvalsTotal",
    "fundingsTotal",
    "approvalsEmpty",
    "fundingsEmpty"
  ]

  connect() {
    start(cable);
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          this.updateAlertTotals();
        }
      }
    };
    const config = { childList: true };
    var observer = new MutationObserver(callback);
    observer.observe(this.approvalsTabTarget, config);
    observer.observe(this.fundingsTabTarget, config);

    this.updateAlertTotals();
  }

  updateAlertTotals() {
    const approvalsTotal = [...this.approvalsTabTarget.children].filter(value => value.tagName === 'TURBO-FRAME').length;
    const fundingsTotal = [...this.fundingsTabTarget.children].filter(value => value.tagName === 'TURBO-FRAME').length

    this.approvalsTotalTarget.innerHTML = approvalsTotal;
    this.fundingsTotalTarget.innerHTML = fundingsTotal;

    if (approvalsTotal == 0) {
      this.showEmptyAlert(this.approvalsEmptyTarget);
    } else {
      this.hideEmptyAlert(this.approvalsEmptyTarget);
    }

    if (fundingsTotal == 0) {
      this.showEmptyAlert(this.fundingsEmptyTarget);
    } else {
      this.hideEmptyAlert(this.fundingsEmptyTarget);
    }
  }

  hideEmptyAlert(element) {
    element.classList.add("hidden");
  }

  showEmptyAlert(element) {
    element.classList.remove("hidden");
  }
}
