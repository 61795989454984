export default class LineItem {
  constructor({ id = null, quantity = 1, description = null, price = null, item_type = null,
    consumer_product_type = null, current_product_type = null, condition = null, damaged = false,
    damage = null } = {}) {

    this.id = id
    this.quantity = quantity
    this.description = description
    this.price = price
    this.item_type = item_type
    this.consumer_product_type = consumer_product_type
    this.current_product_type = current_product_type
    this.condition = condition
    this.damaged = damaged
    this.damage = damage
  }

  update(attributes) {
    Object.keys(attributes).forEach(key => {
      this[key] = attributes[key]
    })
  }
}
