import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "status"
  ]

  optOut(event) {
    const button = event.currentTarget;
    button.disabled = true;
    this.submitServiceDecision(button.dataset.url);
  }

  submitServiceDecision(url) {
    const request = window.acima.fetchInit(
      { method: "DELETE" }
    );
    fetch(url, window.acima.fetchInit(request))
      .then(res => res.json())
      .then(json => this.handleResponse(json))
      .then(() => window.setTimeout(location.reload(), 1000))
      .catch(err => console.error(err));
  }

  handleResponse(response) {
    if (response.success == true) {
      this.statusTarget.classList.add("alert-info");
    } else {
      this.statusTarget.classList.add("alert-error");
    }
    this.statusTarget.innerHTML = response.message;
  }
}
