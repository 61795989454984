import { Controller } from "stimulus";
import { publish } from "../../../helpers/pub_sub";
const SHOW_TAKE_INITIAL_PAYMENT_DIALOG_EVENT =
  "showTakeInitialPaymentDialogEvent";

import {
  showRefundInitialPaymentDialog
} from "./refund_initial_payment_dialog_controller";
import {
  showRetryOptionalPaymentDialog
} from "./optional_payments/retries_dialog_controller";
import {
  showReceiptDialog
} from "./view_receipt_dialog_controller";

export default class extends Controller {
  openTakeInitialPaymentDialog() {
    const render = this.getThenRenderNextView(
      document.getElementById("take-initial-payment-modal"),
      this.data.get("takeInitialPaymentUrl")
    );
    const publishEvent = () => {
      publish(SHOW_TAKE_INITIAL_PAYMENT_DIALOG_EVENT);
    };

    render.then(() => publishEvent())
      .catch(err => console.error(err));
  }

  openRefundInitialPaymentDialog() {
    const render = this.getThenRenderNextView(
      document.getElementById("refund-initial-payment-modal"),
      this.data.get("refundInitialPaymentUrl")
    );

    render.then(() => showRefundInitialPaymentDialog())
      .catch(err => console.error(err));
  }

  sendAthPayment(event) {
    event.target.classList.add("disabled")
    
    const url = this.data.get("athPaymentUrl")
    const leaseId = this.data.get("leaseId")

    const request = window.acima.fetchInit(
      {
        method: "POST",
        body: JSON.stringify({
          lease_id: leaseId,
        })
      }
    );

    let responseStatus = false
    const athMessage = document.getElementById("ath_payment_message")

    fetch(url, request)
      .then((resp) => {
        if(resp.ok) responseStatus = true;
        return resp.json();
      })
      .then((jsonResponse) => {
        athMessage.classList.remove("alert-danger");
        athMessage.classList.add("alert-success");

        if (!responseStatus) {
          athMessage.classList.remove("alert-success");
          athMessage.classList.add("alert-danger");
        }
        athMessage.textContent = jsonResponse.message;
      })
      .catch((err) => {
        athMessage.classList.remove("alert-success");
        athMessage.classList.add("alert-danger");
        athMessage.textContent = err;
      })
      .finally(() => event.target.classList.remove("disabled"))
  }

  openRetryOptionalPaymentDialog() {
    const render = this.getThenRenderNextView(
      document.getElementById("retry-optional-payment-modal"),
      this.data.get("retryOptionalPaymentUrl")
    );

    render.then(() => showRetryOptionalPaymentDialog())
      .catch(err => console.error(err));
  }

  openViewReceiptDialog(event) {
    event.preventDefault();
    const render = this.getThenRenderNextView(
      document.getElementById("view-receipt-modal"),
      event.target.dataset.url,
    );

    render.then(() => showReceiptDialog())
      .catch(err => console.error(err));
  }

  getThenRenderNextView(modalDiv, url) {
    const request = { method: "GET" };

    return fetch(url, window.acima.fetchInit(request))
      .then(response => response.text())
      .then(text => modalDiv.innerHTML = text)
      .catch(err => console.error(err));
  }
}
