import {Controller} from "stimulus";
import {publish, subscribe} from "../../../helpers/pub_sub";

/**
 * Event names for pub/sub
 */
const SHOW_EVENT_NAME = "showDelayAlertDialogEvent";
const CLOSE_EVENT_NAME = "closeDelayAlertDialogEvent";

/**
 * Dispatches the show event
 *
 * This is the public interface for showing the dialog
 *
 * @param {Object} dialogData data to pass to the dialog when it is shown
 */
export function showDelayAlertDialog(dialogData) {
  publish(SHOW_EVENT_NAME, dialogData);
}

/**
 * Dispatches the close event
 *
 * This is the public interface for closing the dialog
 */
export function closeDelayAlertDialog() {
  publish(CLOSE_EVENT_NAME, {});
}

/**
 * Manages delay alert dialog
 * */
export default class extends Controller {
  static targets = [
    "delayTime",
    "delayButtons",
  ];

  /**
   * connect controller,register dialog and subscribe to publishing
   */
  connect() {
    dialogPolyfill.registerDialog(this.element);
    subscribe(SHOW_EVENT_NAME, this.showDialog);
    subscribe(CLOSE_EVENT_NAME, this.closeDialog);
  }

  /**
   * Closes out the dialog and resets local data
   */
  closeDialog = () => {
    this.delayTimeTarget.value = "4h";
    this.highlightActiveButton("4h");
    if (this.element.open) {
      this.element.close();
    }
  }

  /**
   * Shows the dialog and sets the on delay callback
   *
   * @param {Object} data data bag for setting up the dialog
   * @param {Function} data.onDelay callback to trigger when the viewer submits
   */
  showDialog = ({onDelay}) => {
    this.onDelay = onDelay;
    if (!this.element.open) {
      this.element.showModal();
    }
  }

  /**
   * Triggers the on delay callback and closes the dialog. This triggers when
   * the viewer submits the delay and release dialog.
   */
  delayAlerts() {
    this.onDelay({delayTime: this.delayTimeTarget.value});
    this.closeDialog();
  }

  /**
   * Sets the delay time value from the quick select buttons. Triggers whenever
   * a delay button is clicked. Highlights the active button that has been
   * clicked.
   *
   * @param {Event} e event that triggered the action
   */
  setDelayTime(e) {
    const timeValue = e.currentTarget.textContent;
    this.delayTimeTarget.value = timeValue;
    this.highlightActiveButton(timeValue);
  }

  /**
   * Sets the delay time value from the custom entry field. Triggers whenever
   * the field is manually changed. Highlights any active buttons if the custom
   * value matches one of the quick select options.
   *
   * @param {Event} e event that triggered the action
   */
  setCustomTime(e) {
    const timeValue = e.currentTarget.value;
    this.highlightActiveButton(timeValue);
  }

  /**
   * Highlights the button that matches the current delay time if one exists.
   *
   * Iterates through all the quick select buttons and toggles the `orange`
   * class as needed to highlight the appropriate button.
   *
   * @param {string} delayTime coded time to delay alerts for
   */
  highlightActiveButton(delayTime) {
    const buttons = this.delayButtonsTarget.querySelectorAll(".btn");
    buttons.forEach((button) => {
      if (button.textContent === delayTime) {
        button.classList.add("orange");
      } else {
        button.classList.remove("orange");
      }
    });
  }
}
