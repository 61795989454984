import { Controller } from "stimulus";

/** */
export default class extends Controller {
  static targets = [
    "dialog"
  ];

  connect() {
    dialogPolyfill.registerDialog(this.dialogTarget);
  }

  showDialog() {
    this.dialogTarget.showModal();
  }

  closeDialog() {
    this.dialogTarget.close();
  }
}
