import { Controller } from "stimulus";
/**
 * Handles requests to claim a lease condition alert
 */
export default class extends Controller {
  handleResponse(jsonResponse) {
    const { success, message } = jsonResponse;
    if (!success) {
      Rollbar.error(message);
      return false;
    }
    window.open(this.data.get("contract-url"));
  }

  claimViaFetch() {
    const eventTarget = this.element;
    const lockUrl = this.data.get("lock-url");
    const request = window.acima.fetchInit({
      method: "POST",
      body: JSON.stringify(this.params)
    });

    eventTarget.setAttribute("disabled", "");

    fetch(lockUrl, request)
      .then((response) => response.json())
      .then((jsonResponse) => this.handleResponse(jsonResponse))
      .catch((err) => console.log(err));
  }

  claimAlert() {
    // TODO: Will implement this without fetch
    this.claimViaFetch();
  }

  get params() {
    return {
      leaseId: this.data.get("contract-id"),
      leaseConditionId: this.data.get("lease-condition-id"),
      minutes: this.data.get("minutes"),
      contractId: this.data.get("contract-id")
    };
  }
}
