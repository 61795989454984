import { Controller } from "stimulus";
import cable from "../channels/helpers/cable.js";
import InitialPaymentEventChannel from "../channels/initial_payment_event_channel.js";

/**
* Handles submitting opening loading modal and submitting payment
* Payment happens asynchronous
*/
export default class extends Controller {
  static targets = ["message", "submit"]

  /**
   * Establishes a Web Socket connection used to refresh or update the DOM
   * when certain events are triggered in the payment collection process
   */
  connect() {
    const controller = this;
    controller.initialPaymentEventChannel = new InitialPaymentEventChannel({
      guid: controller.data.get("lease-guid"),
      source: "customer",
    });
    cable.subscribe(controller.initialPaymentEventChannel);

    controller.initialPaymentEventChannel.on("failed_or_refunded", (data) => {
      controller.errorAlert(data.message);
    });

    controller.initialPaymentEventChannel.on("payment_collected", (data) => {
      controller.successAlert(data.message);
    });
  }

  /**
  * Opens loading dialog
  * Disables submit button
  * Starts submit payment asynch process
  * @param {event} event
  */
  initiateSubmit(event) {
    event.preventDefault();
    this.submitButton.disabled = true;
    this.submitPayment();

    this.showProcessing();
  }

  /**
  * Sets up processing dialog
  * @param {string} message or locked message
  * @return {dialog} Opens message dialog
  */
  showProcessing(message = this.loadingMessage) {
    const dialog = bootbox.dialog({message:
        `<i class='fa fa-spin fa-spinner fa-lg'></i><span> ${message} </span>`,
    });
    return dialog;
  }

  /**
  * Makes call to chargePath from html to submit payment
  */
  submitPayment() {
    const fetchWith = window.acima.fetchInit({method: "POST"});
    fetch(this.data.get("chargePath"), fetchWith,
    ).then((response) => {
      return response.json();
    }).then((data) => {
      if (!data.success) {
        this.errorAlert();
      } else if (data.lease_locked) {
        this.handleLocked(data.message);
      }
    }).catch((error) => {
      this.errorAlert();
    });
  }

  /**
  * Shows messages(success or error)
  * Defined by called
  * @param {element} row flash row
  * @param {element} flash div
  */
  showAlert(row, flash) {
    bootbox.hideAll();
    row.classList.remove("toggle-hide");
    this.submitButton.disabled = true;

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  /**
  * Sets up processing dialog
  * @param {string} message Displays locked message
  */
  handleLocked(message) {
    bootbox.hideAll();
    this.showProcessing(message);
  }

  /**
  * Sets up success flash
  * @param {string} dataMessage from server
  */
  successAlert(dataMessage) {
    const successRow = document.querySelector(".success-alert-row");
    const successFlash = document.querySelector(".payment-success");
    const messageSpan = document.createElement("span");

    messageSpan.textContent = dataMessage;
    successFlash.appendChild(messageSpan);

    this.showAlert(successRow, successFlash);
  }

  /**
  * Sets up error flash
  * @param {string} dataMessage from server
  */
  errorAlert(dataMessage = this.data.get("genericPaymentError")) {
    const errorRow = document.querySelector(".error-alert-row");
    const errorFlash = document.querySelector(".payment-error");
    const messageSpan = document.createElement("span");

    messageSpan.textContent = dataMessage;
    errorFlash.appendChild(messageSpan);

    this.showAlert(errorRow, errorFlash);
  }

  /**
  * @return {button} Submit button element
  */
  get submitButton() {
    return this.submitTarget;
  }

  /**
  * @return {message} Please wait message
  */
  get loadingMessage() {
    return this.messageTarget.dataset.message;
  }
}
